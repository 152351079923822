import { AxiosInstance } from "axios"
import { Collection, Maybe, Pdf, PdfFieldMapping } from "generated/api"
import { FieldValuesMap } from "lib/forms/types"
import { FieldValueType } from "lib/apihelpers"
import { formatCurrency, formatDate } from "lib/display/formatters"
import { presentation_schema } from "generated/pschema"

// The value we fill for checkboxes that should be checked.
const PDF_CHECKBOX_VALUE = "Yes"

/**
 * Describes the key-value pairs to populate the PDF with.
 */
type FillValues = { [key: string]: string }

/**
 * Describes the POST body that the /api/pdf endpoint expects.
 */
interface FillRequest {
  collection_id: string
  pdf_title: string
  filename: string
  values: FillValues
}

/**
 * Return true if the UI should present the "Generate PDF" button.
 */
export const shouldShowGeneratePdfButton = (
  currentCollection: Collection,
  formDescriptor: presentation_schema.Form
) => currentCollection.pdfs?.length && formDescriptor.options?.pdf

/**
 * Generates the PDF (as raw bytes) with the form fields filled in.
 */
export const fillPdf = async (
  axios: AxiosInstance,
  pdfConfig: Pdf,
  collectionId: string,
  form: presentation_schema.Form,
  filename: string,
  state: FieldValuesMap,
  suretyContactName: string
) => {
  const pdfTitle = pdfConfig.title

  const valuesForPdf = {
    ...formatPdfValuesByWidgetType(form, state),
    // "surety_bail_poster" is the name of the field in the PDF, per
    // libreoffice or "pdftk ./build/pdfs/emeraldfund-baf.pdf dump_data_fields".
    surety_bail_poster: suretyContactName,
  }

  const finalValuesForPdf = applyPdfFieldMappings(
    pdfConfig.fieldMapping!,
    valuesForPdf
  )

  const fillRequest: FillRequest = {
    collection_id: collectionId,
    pdf_title: pdfTitle,
    filename: filename,
    values: finalValuesForPdf,
  }
  const bytes = await axios.post("pdf", fillRequest, {
    responseType: "arraybuffer",
  })
  return new Blob([bytes.data], {
    type: "application/pdf",
  })
}

/**
 * Transform the field's values to strings according to their type.
 */
export const formatPdfValuesByWidgetType = (
  formSpec: presentation_schema.Form,
  documentState: FieldValuesMap
) => {
  let response: Map<string, string> = new Map()
  for (const slug of Object.keys(documentState)) {
    const fieldDef = formSpec.field_defs.find((f) => f.slug === slug)
    if (!fieldDef) {
      response.set(slug, convertToPdfSafeValue(documentState[slug]))
      continue
    }
    switch (fieldDef.field_options.widget) {
      case "Date":
        response.set(
          fieldDef.slug,
          documentState[fieldDef.slug]
            ? formatDate(documentState[fieldDef.slug])
            : ""
        )
        break
      case "Money":
        response.set(
          fieldDef.slug,
          documentState[fieldDef.slug]
            ? formatCurrency(documentState[fieldDef.slug], 2)
            : ""
        )
        break
      case "Choice":
        response.set(
          fieldDef.slug,
          convertToPdfSafeValue(documentState[fieldDef.slug])
        )
        response.set(
          [
            fieldDef.slug,
            convertChoiceFieldToPdfField(documentState[fieldDef.slug]),
          ].join("_"),
          PDF_CHECKBOX_VALUE
        )
        break
      default:
        response.set(
          fieldDef.slug,
          convertToPdfSafeValue(documentState[fieldDef.slug])
        )
    }
  }

  response.set("id", convertToPdfSafeValue(documentState.id))
  return Object.fromEntries(response)
}

/**
 * Applies any extra mappings from document field to PDF field.
 */
const applyPdfFieldMappings = (
  fieldMapping: Maybe<Array<Maybe<PdfFieldMapping>>>,
  pdfValueMap: FillValues
) => {
  if (!fieldMapping) {
    return pdfValueMap
  }
  fieldMapping
    .flatMap((k) => (k ? [k] : []))
    .forEach((mapping) => {
      pdfValueMap[mapping.pdfFieldName!] = pdfValueMap[mapping.slug]
    })
  return pdfValueMap
}

const convertToPdfSafeValue = (value: FieldValueType) => {
  if (value === null || value === undefined) {
    return ""
  }

  return String(value)
}

const convertChoiceFieldToPdfField = (value: FieldValueType) => {
  return value
    ?.toString()
    .toLowerCase()
    .replace(/[^a-z0-9]/gi, "")
}
